import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Headline from "../components/headline"
import GridTwo from "../components/grid-2"

// 2. 👇
const WpPostTemplate = ({ data: { wpPost } }) => {
  const { title, content, date, featuredImage } = wpPost

  // 3. 👇
  return (
    <Layout>
      <section>
				<a href='/news'>
					<Headline title='News & Events' excerpt='' />
				</a>
        <section className="section blog-post">
          <div className="container2">
            <h5 className="is-size-1">{title}</h5>
            <span className="is-size-5">{`${date}`}</span>
            <br />
            <br />
            <div
              className="is-size-5"
              dangerouslySetInnerHTML={{ __html: content }}
            />
          </div>
        </section>
      </section>
    </Layout>
  )
}

export const query = graphql`
  query($id: String!) {
    wpPost(id: { eq: $id }) {
      date(formatString: "LL")
      title
      content
      featuredImage {
        node {
          mediaItemUrl
        }
      }
    }
  }
`

export default WpPostTemplate
